import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { Layout } from "../components/layout"

interface Props {
  data: {
    vignettes: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const RessourcesGraphiques: React.FC<Props> = ({ data }: Props) => (
  <Layout>
    <Helmet title="Ressources graphiques" />
    <div className="container">
      <div className="row mt-3">
        <div className="main-col">
          <h1>Ressources graphiques</h1>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card my-3">
                <div className="card-body">
                  <a href="/communication/Vignettes-24-02-2019.zip" target="_blank" rel="noopener noreferrer">
                    <h4 className="card-title">Vignettes et guide d&apos;utilisation</h4>
                  </a>
                  <h6 className="cardrow-subtitle mb-2 text-muted">ZIP du 24 février 2019</h6>
                </div>
              </div>
            </div>
          </div>

          <h2 className="my-3">Guide d&apos;utilisation du logo</h2>

          <h3 className="my-3">Vignette</h3>

          <p className="my-3">
            Nous mettons à disposition du grand public un logo au format vignette, carré. Il a été pensé pour être facilement utilisable peu
            importe le support (mandat de compétition, affiche d&apos;événement, site internet, ...).
          </p>

          <Img alt="Aperçu des vignettes" fluid={data.vignettes.childImageSharp.fluid} />

          <h3 className="my-3">Version couleur ou monochrome</h3>

          <p className="my-3">
            La version monochrome ou couleur est à choisir en fonction du fond sur lequel sera utilisé le logo. Si la version en couleur
            n&apos;est pas lisible, choisir soit la déclinaison noire, soit la déclinaison blanche.
          </p>

          <p className="my-3">
            La version blanche peut également être utilisée sur fond mauve (code couleur <code>#693991</code>) ou bleu (code couleur{" "}
            <code>#00B3EA</code>
            ).
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RessourcesGraphiques

export const pageQuery = graphql`
  query VignettesPreview {
    vignettes: file(relativePath: { eq: "img/Vignettes.png" }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
